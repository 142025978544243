import React from "react";
import { icons } from "eva-icons";
import Projects from "./components/Projects";
import RadarChart from "./components/RadarChart";
import Menu from "./components/Menu";
import Header from "./components/Header";
import "./App.css";

const skillsData = [
    {
        label: "Backend - Kotlin",
        value: 4.2,
        dx: 100,
        dy: 80
    },
    {
        label: "AI",
        value: 2.5,
        dx: 80,
        dy: 60
    },
    {
        label: "Web Development ",
        value: 4.5,
        dx: 60,
        dy: 40
    },
    {
        label: "Rust",
        value: 2,
        dx: 40,
        dy: -140
    },
    {
        label: "DBMS",
        value: 3,
        dx: -100,
        dy: -60
    },
    {
        label: "Data Engineering",
        value: 2,
        dx: -120,
        dy: -20
    },
    {
        label: "Design",
        value: 3,
        dx: -80,
        dy: 20
    },
    {
        label: "Leadership",
        value: 2.5,
        dx: 40,
        dy: 80
    }
];

const linkedinIcon = icons["linkedin-outline"].toSvg({
    width: 48,
    height: 48,
    fill: "#fff",
    animation: "zoom"
});

const skillsWaveSVG = (
    <svg id="svgWave" viewBox="0 0 100 10" className="Wave skillsWave">
        <path fill="#F9C22E" d="M0 0 V2 C12 10 30 5 55 8 S75 2 100 5 V0z" />
    </svg>
);

export default class App extends React.Component {
    constructor(props) {
        super(props);

        this.state = { showMenu: false };
    }

    toggleMenu = () => {
        this.setState({ showMenu: !this.state.showMenu });
    };

    scrollTo = link => {
        document.querySelector(link).scrollIntoView();
        this.toggleMenu();
    };

    render() {
        const { showMenu } = this.state;
        return (
            <div className="App">
                <Menu
                    scrollTo={this.scrollTo}
                    toggleShow={this.toggleMenu}
                    show={showMenu}
                />
                <Header toggleMenu={this.toggleMenu} />
                <div className="Works">
                    <div className="Works-title">
                        <svg height={2}>
                            <path className="a0" d="M0 0 H140 0" />
                        </svg>
                        <div>PROJECTS</div>
                    </div>
                    <Projects />
                </div>
                <div className="Blogs">
                    <div className="Works-title">
                        <svg height={2}>
                            <path className="a0" d="M0 0 H140 0" />
                        </svg>
                        <div>SKILLS</div>
                    </div>
                    <div className="Skills-chart">
                        <RadarChart
                            width={Math.min(window.innerWidth, 800)}
                            height={Math.min(window.innerWidth * 0.85, 600)}
                            data={skillsData}
                        />
                    </div>
                </div>
                <div className="Skills Contact">
                    {skillsWaveSVG}
                    <div className="Works-title">
                        <svg height={2}>
                            <path className="a0" d="M0 0 H140 0" />
                        </svg>
                        <div>CONTACT ME</div>
                    </div>
                    <div className="Contact-content">
                        <div className="Contact-linkedin">
                            <a
                                className="Linkedin-icon"
                                href="https://www.linkedin.com/in/sathis-kumar-7a554934"
                            >
                                <div className="Linkedin-icon-wrapper">
                                    <i
                                        dangerouslySetInnerHTML={{
                                            __html: linkedinIcon
                                        }}
                                    />
                                </div>
                            </a>
                            Click here for more information
                        </div>
                        <h4 className="Contact-interested">Interested ?</h4>
                        <div className="Contact-send-mail">
                            Just send an mail to my email id
                        </div>
                        <div className="Vertical-line" />
                        <div className="Contact-email">
                            <a href="mailto:sathis62@gmail.com">
                                sathis62@gmail.com
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
