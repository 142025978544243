import React, { useState } from "react";
import { useTrail, animated } from "react-spring";

const config = { mass: 5, tension: 2000, friction: 200 };
const MENUS = [
    { label: "Home", link: ".App-header" },
    { label: "Projects", link: ".Works" },
    { label: "Skills", link: ".Blogs" },
    { label: "Contact", link: ".Contact" }
];

function Menu(props) {
    const { show, toggleShow, scrollTo } = props;

    const [toggle] = useState(true);
    const trail = useTrail(MENUS.length, {
        config,
        opacity: toggle ? 1 : 0,
        x: toggle ? 0 : 20,
        height: toggle ? 30 : 0,
        from: { opacity: 0, x: 20, height: 0 }
    });

    if (!show) return null;

    return (
        <div className="menu-wrapper">
            <div className="popover" />
            <div className="menu-outer">
                <div onClick={toggleShow} className="close" />
                <ul>
                    {trail.map(({ x, height, ...rest }, index) => (
                        <animated.li
                            key={MENUS[index]}
                            className="trails-text"
                            style={{
                                ...rest,
                                transform: x.interpolate(
                                    x => `translate3d(0,${x}px,0)`
                                )
                            }}
                        >
                            <animated.div
                                onClick={() => scrollTo(MENUS[index].link)}
                                style={{ height }}
                            >
                                {MENUS[index].label}
                            </animated.div>
                        </animated.li>
                    ))}
                </ul>
            </div>
        </div>
    );
}

export default Menu;
